// console.log('Setting up Webpack 5. Hurray!!');

// IMPORT UTILITY FUNCTIONS AND OBJECTS FROM UTILITIES
import { addition, dom } from './utilities';

// Import bootstrap javascript bundle as a whole
const bootstrap = require('bootstrap/dist/js/bootstrap.bundle');

// Import main style sheet into javascript bundle
import styles from '../scss/main.module.scss';

// addition(2, 3);

{
  // SIMULATE CLOSE MODAL FROM ANOTHER

  const $modals = document.querySelectorAll('.modal');
  $modals.forEach((modal) => {
    modal.addEventListener('show.bs.modal', function () {
      $modals.forEach((_modal) => {
        let currentModal = bootstrap.Modal.getInstance(_modal);
        if (this != _modal && currentModal) currentModal.hide();
      });
    });
  });
}

// INITIALIZE/ENABLE BOOTSTRAP TOOLTIP FOR ALL TOOLTIP ELEMENTS
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerElement) {
  return new bootstrap.Tooltip(tooltipTriggerElement);
});

// MANUALLY CREATE/UPDATE TOOLTIP
// let refreshPage = new bootstrap.Tooltip(dom.refreshPage);
// refreshPage.show();

// Pass current date into footer
const footerDate = new Date().getFullYear();
const footerPara = `<p class="copyright-text text-center text-white mb-0">Copyright &copy; ${footerDate} Pacific and Gold Limited</p>`;
const getFullYearArr = Array.from(dom.getFullYear);
for (const cur of getFullYearArr) {
  cur.innerHTML = footerPara;
}

// Show Contact details
const office = document.getElementById('office');
const contactUs = document.getElementById('contact');
const closeBtn = document.getElementById('closeBtn');
const contactDetails = document.querySelector('.contactDetails');
const closeFindUs = new bootstrap.Tooltip(closeBtn);

office.addEventListener('click', (e) => {
  contactDetails.classList.add('show');
  cqcRating.classList.add('d-none');

  // MANUALLY CREATE/UPDATE TOOLTIP
  closeFindUs.show();
  closeCQC.hide();
});

closeBtn.addEventListener('click', (e) => {
  contactDetails.classList.remove('show');
  closeFindUs.hide();
});
contactUs.addEventListener('click', (e) => {
  contactDetails.classList.remove('show');
  cqcRating.classList.add('d-none');
  closeFindUs.hide();
  closeCQC.hide();
});

// Show / Hide Quality care commission rating

const cqcMenu = document.getElementById('cqc-menu');
const cqcRating = document.querySelector('.cqc-rating');
const cqcCloseButton = document.getElementById('cqc-closeBtn');
const closeCQC = new bootstrap.Tooltip(cqcCloseButton);
if (cqcMenu) {
  cqcMenu.addEventListener('click', (e) => {
    if (e.target.closest('#cqc-menu')) {
      cqcRating.classList.remove('d-none');
      contactDetails.classList.remove('show');

      // MANUALLY CREATE/UPDATE TOOLTIP
      closeCQC.show();
      closeFindUs.hide();
    }
  });
}

cqcCloseButton.addEventListener('click', (e) => {
  cqcRating.classList.add('d-none');
  closeCQC.hide();
});

// Go back to top
dom.backToTopBtn.addEventListener('click', () => {
  dom.appBody.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
});

const backToTop = () => {
  var scrollY = window.scrollY;
  var navTop = 110;
  // console.log(scrollY);
  // window.scrollY > navTop ? dom.backToTopBtn.classList.add('show') :dom.backToTopBtn.classList.remove('show');
  if (window.scrollY > navTop) {
    dom.backToTopBtn.classList.add('show');
  } else if (window.scrollY < navTop) {
    dom.backToTopBtn.classList.remove('show');
  }
};
window.addEventListener('scroll', backToTop);

{
  // GDPR

  // Prevent display of Cookie and Privacy notice banner once user acknowledges it
  if (localStorage.gdprAccepted) {
    dom.jsGdpr.classList.add('gdprRemove');
    dom.jsCover.classList.add('gdprRemove');
  }

  var gdprNotice = 'User Closed Notification';
  dom.jsCloseGdpr.addEventListener('click', () => {
    // Reverse Animate GDPR
    const gdpr = dom.gdpr;
    var position = -2;
    var callBackInterval = setInterval(animate, 10);

    function animate() {
      if (position === -235) {
        clearInterval(callBackInterval);
      } else {
        position = position - 1; //position++;
        gdpr.style.bottom = position + 'px';
      }
    }

    setTimeout(() => {
      dom.jsGdpr.classList.remove('gdpr');
      dom.jsGdpr.classList.add('gdprRemove');
      dom.jsCover.classList.add('gdprRemove');
      localStorage.setItem('gdprAccepted', gdprNotice);
    }, 1000);
  });

  // Animate GDPR
  const gdpr = dom.gdpr;
  var position = -180;
  var callBackInterval = setInterval(animate, 10);

  function animate() {
    if (position === -2) {
      clearInterval(callBackInterval);
    } else {
      position = position + 1; //position++;
      gdpr.style.bottom = position + 'px';
    }
  }
}

(() => {
  const emailForm = document.querySelector('.sendEmail');
  if (emailForm) {
    emailForm.addEventListener('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }
})();

// The code closes bootstrap toggle menu when a menu item or other items on the window is clicked
document.addEventListener('click', (e) => {
  const navTogglerIcon = document.querySelector('.navbar-toggler-icon');
  const navToggler = document.querySelector('.navbar-toggler');
  const navCollapse = document.querySelector('.navbar-collapse');
  if (e.target.matches('.navbar-toggler-icon')) {
    navToggler.classList.add('menu-show');
  }
  if (!e.target.matches('.navbar-toggler-icon') && navToggler.classList.contains('menu-show')) {
    navToggler.classList.add('collapsed');
    navToggler.setAttribute('aria-expanded', 'false');
    navCollapse.classList.remove('show');
    navToggler.classList.remove('menu-show');
  }
});

// Remove Anchor tags (hash) from SPA URLs
const section = document.querySelectorAll('a[href*="#"]');

for (const cur of section) {
  let counter = 0;
  cur.addEventListener('click', (e) => {
    counter++;
    var section = e.target.href.split('/')[3].split('-')[1];
    // console.log(section);
    // console.log(location);
    // console.log(location.pathname);
    setTimeout(() => {
      // history.replaceState('', '', window.location.origin + window.location.pathname + window.location.search);
      history.replaceState('', '', window.location.origin + counter <= 1 ? window.location.pathname : window.location.pathname.slice(0, 1) + section);
    }, 3);
  });
}
