// Addition function
export function addition(a, b) {
  console.log(a + b);
}

// DOM ELEMENTS
export const dom = {
  getFullYear: document.querySelectorAll('.getFullYear'),
  jsCover: document.getElementById('jsCover'),
  jsGdpr: document.getElementById('js__gdpr'),
  gdpr: document.querySelector('.gdpr'),
  gdprRemove: document.querySelector('.gdprRemove'),
  jsCloseGdpr: document.getElementById('js__close__gdpr'),
  appContainer: document.getElementById('appContainer'),
  appBody: document.getElementById('appBody'),
  backToTopBtn: document.getElementById('backToTop'),
};
